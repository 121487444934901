<template>
  <a-modal
      :title="title"
      :width="1020"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      :footer="null"
      @ok="handleSubmit"
      @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <div>订单状态：
        <a-tag color="blue">已完成</a-tag>
      </div>
      <div>创建时间：2023年10月15日</div>
      <!--      <a-button type="primary" style="margin-bottom: 10px">添加车辆</a-button>-->
      <div style="height: 600px;margin-top: 20px;overflow-y: scroll">
        <div class="info" v-for="item in list">
          <a-divider orientation="left">装货信息</a-divider>
          <div v-for="i in item.list">
            <a-row>
              <a-col :span="12">
                <div>装货单号： 粤A88888</div>
                <div>装货地址： 李先生</div>
                <div>品名： 测试押运</div>
                <div>罐号： 测试押运</div>
                <div>货主： 测试押运</div>
                <div>联系方式： 测试押运</div>

              </a-col>
              <a-col :span="12" style="text-align: right">
                <!--                <a @click="item.show=true">查看跟单信息</a>-->
                <!--                <a style="color: red;margin-left: 10px">删除</a>-->
              </a-col>
            </a-row>
            <div v-if="item.show">
              <a-divider orientation="left">跟单信息</a-divider>
              <div>装货榜单：
                <img class="image" src="https://www.surely.cool/surely-vue-logo.png" style="width: 60px;height: 60px">
                <img class="image" src="https://www.surely.cool/surely-vue-logo.png" style="width: 60px;height: 60px">
                <img class="image" src="https://www.surely.cool/surely-vue-logo.png" style="width: 60px;height: 60px">
                <img class="image" src="https://www.surely.cool/surely-vue-logo.png" style="width: 60px;height: 60px">
              </div>
              <div>卸货榜单： 未上传</div>
              <div>送货单： 未上传</div>
              <div>现金路桥费用收据： 未上传</div>
            </div>
            <hr>
          </div>

        </div>
        <div class="info" v-for="item in list2">
          <a-divider orientation="left">卸货信息</a-divider>
          <div v-for="i in item.list">
            <a-row>
              <a-col :span="12">
                <div>装货单号： 粤A88888</div>
                <div>装货地址： 李先生</div>
                <div>品名： 测试押运</div>
                <div>罐号： 测试押运</div>
                <div>货主： 测试押运</div>
                <div>联系方式： 测试押运</div>

              </a-col>
              <a-col :span="12" style="text-align: right">
                <!--                <a @click="item.show=true">查看跟单信息</a>-->
                <!--                <a style="color: red;margin-left: 10px">删除</a>-->
              </a-col>
            </a-row>
            <div v-if="item.show">
              <a-divider orientation="left">跟单信息</a-divider>
              <div>装货榜单：
                <img class="image" src="https://www.surely.cool/surely-vue-logo.png" style="width: 60px;height: 60px">
                <img class="image" src="https://www.surely.cool/surely-vue-logo.png" style="width: 60px;height: 60px">
                <img class="image" src="https://www.surely.cool/surely-vue-logo.png" style="width: 60px;height: 60px">
                <img class="image" src="https://www.surely.cool/surely-vue-logo.png" style="width: 60px;height: 60px">
              </div>
              <div>卸货榜单： 未上传</div>
              <div>送货单： 未上传</div>
              <div>现金路桥费用收据： 未上传</div>
            </div>
            <hr>
          </div>

        </div>
      </div>


    </a-spin>
  </a-modal>
</template>
<script>
import * as Api from '@/api/order'
import {STable} from '@/components'
import * as addressApi from "@/api/data/address";
import * as shipperApi from "@/api/data/shipper";
import * as vehicleApi from "@/api/vehicle";
import * as goodsApi from "@/api/data/goodsName";
import * as cargoOwnerApi from "@/api/data/cargoOwner";

export default {
  name: 'ShowOrderVehicle',
  components: {
    STable,
  },
  data() {
    return {
      // 对话框标题
      title: '',
      // 标签布局属性
      labelCol: {
        span: 4
      },
      // 输入框布局属性
      wrapperCol: {
        span: 18
      },
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      // 当前表单元素
      form: this.$form.createForm(this),
      // 当前记录
      record: {},
      // 当前模式 add新增 edit编辑
      mode: "",

      list: [
        {status: 0, list: [{}, {}], show: false},
      ],
      list2: [
        {status: 0, list: [{}, {}], show: false},
      ],

    }

  },
  methods: {
    /**
     * 显示对话框
     */
    add() {
      this.mode = "add"
      this.title = '查看订单详情'
      this.visible = true
    },

    getData() {

    },

    handleSubmit() {

    },
    handleCancel() {
      this.visible = false

    },
  }
}
</script>
<style scoped lang="less">
.info {
  padding: 10px;
  border: 1px #777777 dashed;
  line-height: 30px;
  margin-bottom: 10px;

  .image {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    border: 1px #e8e8e8 solid;
  }
}
</style>
<script setup>
</script>
